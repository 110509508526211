import * as React from 'react';
import { CustomTheme, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import { elsewhere } from '@styles/theme';
import IconAccommodation from '@components/shared/Icons/IconAccommodation';
import IconTransportation from '@components/shared/Icons/IconTransportation';
import IconExperiences from '@components/shared/Icons/IconExperiences';
import IconRoadbook from '@components/shared/Icons/IconRoadbook';
import IconSupport from '@components/shared/Icons/IconSupport';
import IconConcierge from '@components/shared/Icons/IconConcierge';
import IconTripCrafting from '@components/shared/Icons/IconTripCrafting';
import IconEntryExit from '@components/shared/Icons/IconEntryExit';
import IconNotIncluded from '@components/shared/Icons/IconNotIncluded';

export default function InclusionsExclusions({
  children,
  faqSlot,
  removeNotIncluded
}: {
  children?: React.ReactNode;
  faqSlot: React.ReactNode;
  removeNotIncluded?: boolean;
}) {
  const sm = useMediaQuery((theme: CustomTheme) => theme.breakpoints.up('sm'));
  const md = useMediaQuery((theme: CustomTheme) => theme.breakpoints.up('md'));
  const lg = useMediaQuery((theme: CustomTheme) => theme.breakpoints.up('lg'));

  const listItem = (icon: JSX.Element, text: string, textColor = 'gray-8') => (
    <Typography sx={{ flex: 1, display: 'flex', alignItems: 'center', color: elsewhere.color[textColor] }}>
      <span style={{ width: '24px', height: '24px', marginRight: '8px', color: elsewhere.color[textColor] }}>
        {icon}
      </span>
      {text}
    </Typography>
  );

  return (
    <Stack pb={3} sx={{ mx: { sm: '40px', md: 'auto' } }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={600} sx={{}}>
          Your full-service Elsewhere trip will include:
        </Typography>
        {sm && faqSlot}
      </Stack>
      {removeNotIncluded ? (
        <Grid container>
          <Grid item sm={6} md={12} lg={6}>
            <Stack spacing={2} sx={{ mt: '18px' }}>
              {listItem(<IconAccommodation />, 'Accommodation')}
              {listItem(<IconTransportation />, 'Ground transportation')}
              {listItem(<IconExperiences />, 'Super cool experiences')}
              {listItem(<IconRoadbook />, 'Roadbook with local tips and expert recommendations')}
            </Stack>
          </Grid>
          <Grid item sm={6} md={12} lg={6}>
            <Stack spacing={2} sx={{ mt: { xs: 1, sm: '18px' }, ml: { xs: 0, sm: '16px', md: 0, lg: '16px' } }}>
              {listItem(<IconSupport />, '24/7 real-time support')}
              {listItem(<IconConcierge />, 'On-trip concierge service')}
              {listItem(<IconTripCrafting />, 'Personalized trip crafting')}
              {listItem(<IconEntryExit />, 'Entry & exit information and assistance')}
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Stack spacing={2} sx={{ mt: '16px' }}>
              {listItem(<IconAccommodation />, 'Accommodation')}
              {listItem(<IconTransportation />, 'Ground transportation')}
              {listItem(<IconExperiences />, 'Super cool experiences')}
            </Stack>
          </Grid>
          <Grid item={sm} xs={12} sm={4} sx={{ pl: '8px' }}>
            <Stack spacing={2} sx={{ mt: '16px' }}>
              {listItem(<IconRoadbook />, 'Roadbook with local tips and expert recommendations')}
              {listItem(<IconSupport />, '24/7 real-time support')}
              {listItem(<IconConcierge />, 'On-trip concierge service')}
            </Stack>
          </Grid>
          <Grid item={sm} xs={12} sm={4} sx={{ pl: '8px' }}>
            <Stack spacing={2} sx={{ mt: '16px' }}>
              {listItem(<IconTripCrafting />, 'Personalized trip crafting')}
              {listItem(<IconEntryExit />, 'Entry & exit information and assistance')}
              {listItem(<IconNotIncluded />, 'International flights and trip insurance not included', 'red')}
            </Stack>
          </Grid>
        </Grid>
      )}
      {children}
    </Stack>
  );
}
