import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import styles from './Hero.module.scss';
import Search from './Search/Search';
import SearchV1 from './Search/V1/Search';

import useMonarch from '@hooks/useMonarch';
import Logger from '@utils/logger';

const HomepageHero = ({ regionsStore }) => {
  const { monarch } = useMonarch();
  const [useHeroSearchV1, setUseHeroSearchV1] = useState('loading');
  const [heroTitle, setHeroTitle] = useState('loading');

  useEffect(() => {
    monarch({
      // Home Page Hero Search V1
      rid: 'c7c2867a-b026-4022-847b-1ec04318417c',
      callback: function (err, result) {
        if (err) {
          Logger.error(err);
        }

        const { homePageHeroSearchV1 = false } = result;
        setUseHeroSearchV1(homePageHeroSearchV1);
      }
    });

    monarch({
      // Home Page Hero Title
      rid: '5785c10d-3536-401c-8fb9-1eb6a4a44dc2',
      callback: function (err, result) {
        if (err) {
          Logger.error(err);
        }

        const homePageHeroTitle = {
          mainTitle: 'Travel like you mean it.',
          subTitle: "Trips you couldn't plan, even if you wanted to"
        };

        setHeroTitle(result?.homePageHeroTitle || homePageHeroTitle);
      }
    });
  }, []);

  return (
    <div className={classnames('section-hero', styles['homepage-hero'])} style={{ overflow: 'unset' }}>
      <div className="hero">
        <div className="video-frame-container" style={{ overflow: 'unset', marginTop: 22 }}>
          <div className="scroll-lead">
            <span>SCROLL</span>
            <div className="scroll-lead-line"></div>
          </div>
          <div
            data-poster-url="/webflow/videos/hero-home-poster-00001.jpg"
            data-video-urls="/webflow/videos/hero-home-transcode.mp4,videos/hero-home-transcode.webm"
            data-autoplay="true"
            data-loop="true"
            data-wf-ignore="true"
            className="hero-bg-video w-background-video w-background-video-atom homepage-hero__radius"
            style={{ overflow: 'unset' }}
          >
            <video
              className="homepage-hero__radius"
              autoPlay
              loop
              style={{
                backgroundImage: 'url("/webflow/videos/hero-home-poster-00001.jpg")'
              }}
              muted
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              <source src="/webflow/videos/hero-home-transcode.mp4" data-wf-ignore="true" />
              <source src="/webflow/videos/hero-home-transcode.webm" data-wf-ignore="true" />
            </video>

            <div className="hero-title hero-title__section">
              <h1 className="hero-title text-center">{heroTitle?.mainTitle}</h1>
              <h4
                className="text-center hero-subtitle"
                style={{ fontWeight: useHeroSearchV1 !== 'loading' && useHeroSearchV1 ? '400' : '600' }}
              >
                {heroTitle.subTitle}
              </h4>

              {useHeroSearchV1 !== 'loading' ? (
                useHeroSearchV1 ? (
                  <SearchV1 regionsStore={regionsStore} />
                ) : (
                  <Search regionsStore={regionsStore} />
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject('regionsStore')(observer(HomepageHero));
