import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import Image from 'next/image';
import Link from 'next/link';

import styles from './Header.module.scss';
import HamburgerMenu from './HamburgerMenu';

import routes from '@constants/routes';
import GuestControls from '@components/Header/GuestControls/GuestControls';
import UserControls from '@components/Header/UserControls/UserControls';
import { userMobileMenuItems, guestMobileMenuItems, webMenuItems } from '@constants/common';

const Header = ({
  userStore,
  noInitialShadow,
  commercialPage,
  customerArea,
  smallContainer
}: {
  userStore: any;
  noInitialShadow: boolean;
  commercialPage: boolean;
  customerArea: boolean;
  smallContainer?: boolean;
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <header className={styles['header']}>
      <div className={scroll ? 'show-header-shadow' : 'hide-header-shadow'}>
        <div
          className={classnames('header__container', {
            noInitialShadow: noInitialShadow,
            smallContainer: smallContainer
          })}
        >
          <div className="header__container-inner">
            <HamburgerMenu
              customerArea={customerArea}
              items={isDesktop ? webMenuItems : userStore.isAuthorized() ? userMobileMenuItems : guestMobileMenuItems}
              colorful
            />
            <Link href={routes.index}>
              <a
                className={classnames('header__logo', {
                  'header__logo--offset': !userStore.isAuthorized()
                })}
              >
                <Image src="/img/logo-elsewhere-lp.svg" alt="logo" width={137} height={33} />
              </a>
            </Link>
            <div className="header__controls">
              {userStore.isAuthorized() ? (
                <UserControls userStore={userStore} commercialPage={commercialPage} buttonssize="sm" />
              ) : (
                <GuestControls
                  className={isDesktop ? 'header-guest--desktop' : 'header-guest--mobile'}
                  buttonssize="sm"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(Header));
