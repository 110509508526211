import { blueGrey, green, lightBlue, red, yellow } from '@mui/material/colors';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';

import routes from './routes';

const SESSION_KEY = '__user-token__';
const COPYRIGHT_YEAR = 2020;
export const isDevMode = process.env.NODE_ENV === 'development';

export const cuAreaURL = process.env.CU_AREA_URL;

import cohesion from '@constants/cohesion';
export const serverDateFormat = 'YYYY-MM-DD';
export const serverDateUSFormat = 'MM-DD-YYYY';
export const dateFormat = 'MMMM DD, YYYY';
export const timeFormat = 'hh:mm A';
export const dateTimeFormat = 'MMMM DD, YYYY HH:mm';
export const dateWithTextMonthFormat = 'DD MMM YYYY';
export const flightTimeFormat = 'HH:mm';

export const destinationPhotoSize = {
  width: 1320,
  height: 715
};

export const defaultPageSize = 10;

export const destinationPhotoAspect = destinationPhotoSize.width / destinationPhotoSize.height;

export { COPYRIGHT_YEAR, SESSION_KEY };

export const messageTypes = {
  regular: 'regular',
  system: 'system',
  file: 'file'
};

export const imageFileTypes = ['jpg', 'png', 'jpeg'];

export const fileFieldsConfigs = {
  avatar: {
    maxFileSize: 10,
    accept: ['jpg', 'jpeg', 'png'],
    acceptProp: 'image/jpg,image/jpeg,image/png'
  },
  chatAttachment: {
    maxFileSize: 100,
    accept: ['jpg', 'jpeg', 'png', 'pdf', 'txt', 'doc'],
    acceptProp: '.jpg,.jpeg,.png,.pdf,.txt,.doc'
  }
};

export const fileErrors = {
  typeError: (type) => {
    const typesFormatted = isArray(type) ? type.join(', ') : [type];
    const typesPrefix = typesFormatted.length > 1 ? 'types are' : 'type is';
    return `Selected file type is not accepted. Accepted file ${typesPrefix}  ${typesFormatted}`;
  },
  sizeError: (maxSize) => `The max size of file for uploading - ${maxSize} Mb`,
  uploadError: 'Error uploading file',
  openError: 'Error opening file',
  attachmentsLoadError: 'Error loading attachments'
};

export const tripStatuses = {
  preRequest: 'awaiting_verification',
  newRequest: 'new_request',
  crafting: 'crafting',
  deposit: 'deposit',
  fullyPaid: 'fully_paid',
  onTrip: 'on_trip',
  completed: 'completed',
  cancelled: 'cancelled',
  onHold: 'on_hold'
};

export const tripStatusesMap = {
  [tripStatuses.newRequest]: {
    label: 'New request',
    color: yellow['700']
  },
  [tripStatuses.crafting]: {
    label: 'Crafting',
    color: yellow['700']
  },
  [tripStatuses.deposit]: {
    label: 'Deposit',
    color: green[600]
  },
  [tripStatuses.fullyPaid]: {
    label: 'Fully paid',
    color: green[600]
  },
  [tripStatuses.onTrip]: {
    label: 'On trip',
    color: green[600]
  },
  [tripStatuses.completed]: {
    label: 'Completed',
    color: blueGrey[500]
  },
  [tripStatuses.cancelled]: {
    label: 'Cancelled',
    color: red[700]
  },
  [tripStatuses.onHold]: {
    label: 'On hold',
    color: red[700]
  }
};

export const allPaymentsStatuses = {
  new: 'new',
  successful: 'successful',
  failed: 'failed',
  transferwise: 'transferwise',
  transferredToAgent: 'transferred_to_agent'
};

export const agentVisiblePaymentStatuses = {
  new: 'New',
  successful: 'Successful',
  failed: 'Failed'
};

export const agentVisiblePaymentStatusesColors = {
  [agentVisiblePaymentStatuses.new]: lightBlue.A700,
  [agentVisiblePaymentStatuses.failed]: red[700],
  [agentVisiblePaymentStatuses.successful]: green[600]
};

export const agentVisiblePaymentStatusesMap = {
  [allPaymentsStatuses.new]: agentVisiblePaymentStatuses.new,
  [allPaymentsStatuses.failed]: agentVisiblePaymentStatuses.failed,
  [allPaymentsStatuses.successful]: agentVisiblePaymentStatuses.successful,
  [allPaymentsStatuses.transferwise]: agentVisiblePaymentStatuses.successful,
  [allPaymentsStatuses.transferredToAgent]: agentVisiblePaymentStatuses.successful
};

export const tripActionsInStatuses = {
  setPrice: [
    tripStatuses.newRequest,
    tripStatuses.crafting,
    tripStatuses.deposit,
    tripStatuses.fullyPaid,
    tripStatuses.onTrip,
    tripStatuses.completed
  ],
  uploadFiles: [tripStatuses.newRequest, tripStatuses.crafting, tripStatuses.deposit, tripStatuses.fullyPaid],
  onHold: [tripStatuses.crafting, tripStatuses.deposit, tripStatuses.fullyPaid],
  crafting: [tripStatuses.cancelled, tripStatuses.onHold],
  cancelled: [tripStatuses.crafting, tripStatuses.deposit, tripStatuses.fullyPaid, tripStatuses.onHold]
};

export const cancelReasons = [
  {
    label: 'Budget issues',
    value: 'prices'
  },
  {
    label: 'Trip didn’t meet expectations',
    value: 'not_enough'
  },
  {
    label: 'No response from traveler',
    value: 'playing_hard'
  },
  {
    label: 'Client worried about the destination (natural disaster, geopolitical issues, etc)',
    value: 'destination_worries'
  },
  {
    label: 'Client personal reasons',
    value: 'personal'
  }
];

export const cancelReasonsMap = keyBy(cancelReasons, 'value');

export const notifications = {
  chat: {
    message: {
      markUnread: {
        success: 'Message marked as unread',
        error: 'Failed to mark message marked as unread'
      }
    }
  },
  tripFiles: {
    upload: {
      success: (fileType) => `${fileType} successfully uploaded`,
      error: (fileType) => `Failed to upload a new ${fileType}`
    }
  },
  tripStatus: {
    changes: {
      success: (status) => `Trip status changed to "${status}"`,
      error: (status) => `Failed to change status to "${status}"`
    }
  }
};

export const fileTypes = {
  itinerary: 'itinerary',
  terms: 'terms',
  roadbook: 'roadbook'
};

export const insurancesMap = {
  peace_of_mind:
    'Client has purchased a comprehensive travel protection and medical plan with Wanderwell or another provider for all members of this trip.',
  live_on_the_edge:
    'Client declined to purchase a comprehensive travel insurance plan and accepted full responsibility for any loss or expense incurred which would have been covered by the recommended insurance provider.'
};

export const keepInMind = {
  title: 'Double check!',
  text: 'Please verify that the Price of Trip and Itinerary are both updated before saving'
};

export const webMenuItems = [
  {
    title: 'Destinations',
    href: routes.destinations.index,
    hideOnCustomerArea: false
  },
  {
    title: 'How it works',
    href: routes.howItWorks,
    hideOnCustomerArea: false
  },
  {
    title: 'Our Commitments',
    href: routes.ourCommitments,
    hideOnCustomerArea: false
  }
];

export const userMobileMenuItems = [
  {
    title: 'Destinations',
    href: routes.destinations.index,
    hideOnCustomerArea: false
  },
  {
    title: 'How it works',
    href: routes.howItWorks,
    hideOnCustomerArea: false
  },
  {
    title: 'Our Commitments',
    href: routes.ourCommitments,
    hideOnCustomerArea: false
  },
  {
    title: 'Craft your trip',
    href: routes.requestForm.index,
    hideOnCustomerArea: false,
    isButton: true
  }
];

export const guestMobileMenuItems = [
  {
    title: 'Destinations',
    href: routes.destinations.index,
    hideOnCustomerArea: false
  },
  {
    title: 'How it works',
    href: routes.howItWorks,
    hideOnCustomerArea: false
  },
  {
    title: 'Our Commitments',
    href: routes.ourCommitments,
    hideOnCustomerArea: false
  },
  {
    title: 'Login',
    href: routes.auth.login.index,
    hideOnCustomerArea: true,
    isButton: true
  }
];

export const userMenuItems = [
  {
    title: 'My trips',
    href: routes.trips.my
  },
  {
    title: 'My profile',
    href: routes.profile.index
  },
  {
    title: 'My account',
    href: routes.accountPreferences
  },
  {
    title: 'Logout',
    onClick: (userStore: any) => {
      userStore.logout(cohesion.sources.menu);
    }
  }
];

export const guestMenuItems = [
  {
    title: 'Login',
    href: routes.auth.login.index
  }
];

export const countersUpdateInterval = 15 * 1000; // 15sec
